import React from 'react'
import Layout from '../components/layout'
import Header from '../components/header'
import Content from '../components/content'
import Author from '../components/author'
import Wrapper from '../components/wrapper'
import Footer from '../components/footer'
import SEO from '../components/seo'
import { css } from '@emotion/core'

export default class NotFound extends React.Component {
  state = {
    noScroll: false,
  }

  render() {
    const text =
      'De pagina die je bezoekt bestaat niet. Je kunt de url van de pagina controleren op typfouten, of het menu gebruiken om te vinden wat je zoekt.'

    return (
      <Layout>
        <SEO
          title="Pagina niet gevonden"
          description={text}
          noScroll={this.state.noScroll}
        />
        <Header onMenuChange={noScroll => this.setState({ noScroll })}>
          <Content>
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
              }}
            >
              <h1>Pagina niet gevonden</h1>
            </div>
          </Content>
        </Header>
        <Wrapper>
          <Content
            styles={css`
              margin-bottom: calc(60px + 5%);
              text-align: center;
            `}
          >
            <p
              css={css`
                margin: 0;
              `}
            >
              {text}
            </p>
          </Content>
        </Wrapper>
        <Author />
        <Footer />
      </Layout>
    )
  }
}
